<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
        <template slot="tbody">
          <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.items">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
               <vs-td>
                <vs-button
                  size="[detail ? 'small' : '', '']"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                >{{tr.ItemName}}</vs-button>
              </vs-td>
              <vs-td>{{tr.ItemCode}}</vs-td>
              <vs-td>{{tr.QtyRequest}}</vs-td>
              <vs-td>{{tr.QtyReserve}}</vs-td>
            </vs-tr>
        </template>
        </data-table>
       
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
             <vs-col style="padding-bottom: 8px"  vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth" vs-w="4">
             <vs-button 
                  size="small"
                  v-on:click="closeDetail"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                >Close</vs-button>
             </vs-col>
             <vs-col class="vertical-divider" vs-offset="0" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
             <vs-button 
                  size="small"
                  v-on:click="closeDetail"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                ></vs-button>
            </vs-col>
            
          <detail :data-item="dataItem" :edit="false" :delivery-plan="deliveryPlan" :total-qty="totalQty" @refresh="getData"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import detail from './detail.vue'
export default {
  props: ["deliveryPlan","indexItem"],
  components:{
    detail
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "item_code"
      },
      header: [
        {
          text: "SKU Name",
          // width: '5%'
          value: "item_name"
        },
        {
          text: "SKU Code",
          value: "item_code"
        },
        {
          text: "Qty Request",
          value: "qty_request"
        },
        {
          text: "Qty Reserve",
          value: "qty_reserve"
        }
      ],
      responseData: {},
      limits: [5, 10, 25, 50],
      limitShow: 10,
      drawData: 0,
      isActive: 1,
      checked: [],
      modelCheck: [],
      checkedAll: false,
      totalQty:0,
      search: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,
      data: [{ id: 1 }],
      dataItem: 1,
      salesOrder:0
    };
  },
  mounted() {
    this.reloadData(this.params)
    
  },
  methods: {
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft/item", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            delivery_plan_id: this.deliveryPlan,
            // territory_id: this.territory
          }
        })
        .then(
          resp => {
            console.log("aye ini")
            if (resp.code == 500) {
              this.$vs.loading.close();
            } else if(resp.code == 200) {
              this.$vs.loading.close();
              this.data = resp.data.items;
              this.responseData = resp.data;
              if(this.data.length < 1){
                this.$emit("hideItem",this.indexItem)
              }else{
                this.$emit("changeTabs",this.indexItem,2)
              }
              this.checkedAll = false;
            }
            else {
              this.$vs.loading.close();
            }
          }
        );
    },
    test() {
      console.log("sip");
    },
    
    clickTr(val) {
        this.closeDetail();
        this.dataItem = val.ItemCode;
        this.totalQty = val.QtyReserve
        this.detail = true;
    },
    closeDetail(){
        this.detail = false;
    },
    page(val) {
      this.getData(val);
      return val;
    },
    changeLimit(val) {
      this.limitShow = val;
      this.getData(this.isActive);
    },
    getData(val = 1) {
      this.isActive = val;
      this.detail = false 
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft/item", {
          params: {
            delivery_plan_id: this.deliveryPlan,
            length: this.limitShow,
            search: this.search,
            draw: this.drawData,
            start: this.limitShow * (val - 1)
          }
        })
        .then(resp => {
            this.drawData = this.drawData + 1;
            this.data = resp.data.items;
            // this.recordsTotal = resp.data.recordsTotal;
            // this.maxPage = Math.ceil(this.data.recordsTotal / this.limitShow);
            // this.dataEnd = resp.data.item.length;
            // this.checkedAll = false;
            this.$vs.loading.close();
          
        });
    }
  }
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
  
}
.vertical-divider {
    border-left: 1px solid #7367f0;
    min-height:800px;
    padding:5px;
}
</style>