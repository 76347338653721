<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        <vs-col
          style="padding-bottom: 10px"
          vs-offset="0"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-row vs-w="12">
            <vs-col vs-justify="left" vs-w="3" vs-align="left"  class="sm:p-2 p-4">
             
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" class="sm:p-2 p-4">
            
            </vs-col>
          </vs-row>
        </vs-col>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.deliveryPlan">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-td>
                <vs-button
                  size="[detail ? 'small' : '', '']"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                >{{tr.DeliveryPlanCode}}</vs-button>
              </vs-td>
              <vs-td>{{tr.WarehouseName}}</vs-td>
              <vs-td>{{tr.CompanyName}}</vs-td>
              <vs-td>{{tr.CountSalesOrder}}</vs-td>
            </vs-tr>
          </template>
        </data-table>

        
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>

            <detail :data-id="dataId" :sales-order="salesOrder"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import detail from "./detail.vue";
export default {
  components: {
    detail
  },
  watch: {
    territory: function() {
      this.reloadData(this.params);
    }
  },
  props: ["territory"],
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code"
      },
      header: [
        {
          text: "Delivery Plan Number",
          // width: '5%'
          value: "code"
        },
        {
          text: "Warehouse",
          // value: "customer_name"
        },
        {
          text: "Company",
          sortable: false
        },
        {
          text: "Sales Order",
          // value: "code"
        }

      ],
      responseData: {},
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      dataId: 1,
      salesOrder: 0
    };
  },
  mounted() {
    this.reloadData(this.params);
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  
  methods: {
    reloadData(params) {
      this.params = params;
      console.log(params)
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/complete", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_id: this.territory.id
          }
        })
        .then(
          resp => {
            if (resp.code == 500) {
              this.$vs.loading.close();
            } else if(resp.code == 200) {
              this.$vs.loading.close();
              this.data = resp.data.deliveryPlan;
              this.responseData = resp.data;
              this.checkedAll = false;
            }
            else {
              this.$vs.loading.close();
            }
          }
        );
    },
    test() {
      // console.log("sip");
      this.dateShow = true;
    },
    clickTr(val) {
      this.closeDetail();
      console.log(val)
      setTimeout(function() {}, 3000);
      this.dataId = val.DeliveryPlanID;
      this.salesOrder = val.DeliveryPlanCode;
      this.detail = true;
    },
    closeDetail() {
      this.detail = false;
    },
    
  }
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>