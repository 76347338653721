<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        <vs-col
          style="padding-bottom: 10px"
          vs-offset="0"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-row vs-w="12">
            <vs-col
              vs-justify="left"
              vs-w="3"
              vs-align="left"
              class="sm:p-2 p-4"
            >
            </vs-col>
            <vs-col vs-justify="left" vs-align="left" class="sm:p-2 p-4">
              <vs-button
                v-on:click="SendDeliveryPlan()"
                color="success"
                icon-pack="feather"
                icon="icon-share"
                >Release DP To Warehouse</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-col>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="15%">
              <vs-checkbox v-on:click="addAllChecked()" v-model="checkedAll"
                >Check All</vs-checkbox
              >
            </th>
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.deliveryPlan"
            >
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-td>
                <vs-checkbox
                  :checked="checked.includes(tr.DeliveryPlanID)"
                  v-on:click="addChecked(tr.DeliveryPlanID)"
                ></vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-button
                  size="[detail ? 'small' : '', '']"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                  >{{ tr.DeliveryPlanCode }}</vs-button
                >
              </vs-td>
              <vs-td>{{ tr.WarehouseName }}</vs-td>
              <vs-td>{{ tr.CountSalesOrder }}</vs-td>
              <vs-td>
                <vx-input-group
                  class="mb-base form-element-demo"
                  prependClasses="border border-solid form-element-border border-r-0"
                >
                  <ul class="centerx">
                    <li>
                      
                    </li>
                    <li v-for="(option, index) in pickingOption" :key="index">
                      <vx-tooltip :text="info[index]" position="left">
                        <label :for="option + tr.DeliveryPlanID">
                          <input
                            type="radio"
                            :id="option + tr.DeliveryPlanID"
                            :value="option"
                            v-model="picking[tr.DeliveryPlanID]"
                          />
                          <span>
                            {{ option }}
                          </span>
                        </label>
                          <!-- <feather-icon icon='AlertOctagonIcon' svgClasses='h-4 w-4' class='ml-1'  /> -->
                      </vx-tooltip>
                    </li>
                  </ul>
                </vx-input-group>
              </vs-td>
              <vs-td>
                <flat-pickr
                  :config="config"
                  v-model="date[tr.DeliveryPlanID]"
                ></flat-pickr>
              </vs-td>
              <vs-td>{{ tr.CompanyName }}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? detailShow : '', detailHide]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>

            <detail :data-id="dataId" :sales-order="salesOrder"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import detail from "./detail.vue";
import Datepicker from "vuejs-datepicker";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ConfirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate.js";
import moment from "moment";
import vSelect from "vue-select";
export default {
  components: {
    detail,
    Datepicker,
    flatPickr,
    vSelect,
  },
  watch: {
    territory: function () {
      // this.getData();
      this.reloadData(this.params);
    },
  },
  props: ["territory"],
  data() {
    return {
      config: {
        // wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "M j, Y",
        // altInput: true,
        dateFormat: "j F Y H:i",
        minDate: new Date(),
        enableTime: true,
        minTime: "00:00",
        plugins: [new ConfirmDatePlugin()],
      },
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code",
      },
      header: [
        {
          text: "Delivery Plan Number",
          // width: '5%'
          value: "code",
        },
        {
          text: "Warehouse",
          // value: "customer_name"
        },
        {
          text: "Sales Order",
          // value: "code"
        },
        {
          text: "Picking Method",
          width: "15%",

          // value: "code"
        },
        {
          text: "Date",
          sortable: false,
        },
        {
          text: "Company",
          sortable: false,
        },
      ],
      responseData: {},
      limits: [5, 10, 25, 50],
      limitShow: 10,
      date: [],
      dateShow: false,
      drawData: 0,
      isActive: 1,
      checked: [],
      modelCheck: [],
      checkedAll: false,
      search: "",
      pickingOption: [],
      picking: [],
      defaultPicking: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,
      info:["Metode pengambilan barang untuk beberapa Sales Order yang dilakukan bersama-sama diwaktu yang sama",
        "Metode pengambilan barang yang dilakukan secara spesifik untuk masing-masing produk",
        "Metode pengambilan barang yang dilakukan untuk masing-masing Sales Order"],
      data: [{ id: 1 }],
      dataId: 1,
      salesOrder: 0,
    };
  },
  mounted() {
    this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  computed: {
    currentPage: {
      get() {
        return 1;
      },
      set(val) {
        this.page(val);
        // this.getData()
        // return this.pageActive
      },
    },
  },
  methods: {
    reloadData(params) {
      this.params = params;
      console.log(this.territory);
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/release", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_id: this.territory.id,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.deliveryPlan;
            this.responseData = resp.data;
            this.checkedAll = false;
            this.pickingOption = JSON.parse(resp.data.pickingMethod[0].Options);
            this.defaultPicking =
              resp.data.pickingMethod[0].Value != ""
                ? resp.data.pickingMethod[0].Value
                : resp.data.pickingMethod[0].DefaultValue;
            this.data.forEach((tr) => {
              console.log(
                resp.data.pickingMethod.find(
                  (v) => v.CompanyID == tr.WarehouseCompanyID
                )
              );
              var pickingMethod = resp.data.pickingMethod.find(
                (v) => v.CompanyID == tr.WarehouseCompanyID
              );
              this.date[tr.DeliveryPlanID] = new Date(); // "20 July 2019 10:00:00"
              this.picking[tr.DeliveryPlanID] =
                pickingMethod.Value != ""
                  ? pickingMethod.Value
                  : pickingMethod.DefaultValue;
            });
          } else {
            this.$vs.loading.close();
          }
        });
    },
    test() {
      // console.log("sip");
      this.dateShow = true;
    },
    getBulan(i) {
      return i++;
    },
    openDatepicker() {
      console.log("asd");
      this.dateShow = true;
    },
    dateFormat(value) {
      return moment(String(value)).format("YYYY-MM-DD HH:mm");
    },
    SendDeliveryPlan() {
      this.$vs.loading();
      console.log(this.date);
      this.$vs.loading;
      var date = '{"key_0":"0"';
      for (var i = 0; i < this.checked.length; i++) {
        date +=
          ',"key_' +
          this.checked[i] +
          '":"' +
          this.dateFormat(this.date[this.checked[i]]) +
          '"';
      }
      date += "}";
      var pickingMethod = '{"key_0":"0"';
      for (i = 0; i < this.checked.length; i++) {
        pickingMethod +=
          ',"key_' +
          this.checked[i] +
          '":"' +
          this.picking[this.checked[i]] +
          '"';
      }
      pickingMethod += "}";
      this.$http
        .post("api/v1/allocation-engine/release", {
          delivery_plan_ids: this.checked,
          due_date: JSON.parse(date),
          picking_method: JSON.parse(pickingMethod),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "success",
              text: "Success",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "failed",
              text: "Failed",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.checked.splice(0, this.checked.length);
          this.$vs.loading.close();
        });
    },
    clickTr(val) {
      this.closeDetail();
      console.log(val);
      setTimeout(function () {}, 3000);
      this.dataId = val.DeliveryPlanID;
      this.salesOrder = val.DeliveryPlanCode;
      this.detail = true;
    },
    closeDetail() {
      this.detail = false;
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
      } else {
        this.checked.push(val);
      }
    },
    addAllChecked() {
      // console.log(this.checkedAll)
      if (this.checkedAll == false) {
        for (var i = 0; i < this.data.length; i++) {
          if (this.checked.includes(this.data[i].DeliveryPlanID)) {
            //
          } else {
            this.checked.push(this.data[i].DeliveryPlanID);
            console.log(this.data[i].DeliveryPlanID + "asa");
          }
        }
      } else {
        for (var j = 0; j < this.data.length; j++) {
          this.checked.splice(
            this.checked.indexOf(this.data[j].DeliveryPlanID),
            1
          );
        }
      }
      // this.checkedAll = null;
      console.log(this.checkedAll);
    },
    page(val) {
      this.getData(val);
      return val;
    },
    changeLimit(val) {
      this.limitShow = val;
      this.getData(this.isActive);
    },
    getData(val = 1) {
      this.isActive = val;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/release", {
          params: {
            length: this.limitShow,
            search: this.search,
            draw: this.drawData,
            start: this.limitShow * (val - 1),
            territory_id: this.territory.ID,
          },
        })
        .then(
          (resp) => {
            console.log(resp);
            if (resp.code == 500) {
              this.$vs.loading.close();
            } else {
              this.drawData = this.drawData + 1;
              this.data = resp.data.deliveryPlan;
              this.recordsTotal = resp.recordsTotal;
              this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
              this.dataEnd = resp.data.deliveryPlan.length;
              this.checkedAll = false;
              this.$vs.loading.close();
            }
            this.data.forEach((tr) => {
              this.date[tr.DeliveryPlanID] = new Date(); // "20 July 2019 10:00:00"
            });
            // date[tr.DeliveryPlanID]
          },
          function (error) {
            console.log(error);
          }
        );
    },
  },
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
/* pretty radio */

label > input[type="radio"] {
  display: none;
}
label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.3rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}
label > input[type="radio"]:checked + * {
  color: teal;
}
label > input[type="radio"]:checked + *::before {
  background: radial-gradient(teal 0%, teal 40%, transparent 50%, transparent);
  border-color: teal;
}
 
/* basic layout */
 
fieldset {
  margin: 20px;
  max-width: 400px;
}
label > input[type="radio"] + * {
  display: inline-block;
  padding: 0.5rem 1rem;
} 
</style>