<template>
  <div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <!-- 
       ADDONs
         
        -->
        <vs-col
          style="padding-bottom: 10px"
          vs-offset="0"
          vs-type="flex"
          vs-justify="left"
          vs-align="center"
          vs-w="12"
        >
          <vs-button
            v-on:click="AllocationEngine()"
            color="success"
            icon-pack="feather"
            icon="icon-inbox"
            >Run Allocation</vs-button
          >
          &nbsp;
          <vs-button
            v-on:click="Cancel()"
            color="danger"
            icon-pack="feather"
            icon="icon-x-circle"
            >Cancel</vs-button
          >
        </vs-col>
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="15%">
              <vs-checkbox v-on:click="addAllChecked()" v-model="checkedAll"
                >Check All</vs-checkbox
              >
            </th>
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.salesOrder"
              v-bind:class="[dataId == tr.ID ? 'colored' : '', '']"
              
            >
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-button
                v-if="dataId == tr.ID"
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
              <vs-td>
                <vs-checkbox
                  :checked="checked.includes(data[indextr].ID)"
                  v-on:click="addChecked(data[indextr])"
                  >Check</vs-checkbox
                >
              </vs-td>
              <vs-td>
                <vs-button
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                  >{{ data[indextr].Code }}</vs-button
                >
                <br />
                <br />
                Code : {{ tr.Code }}
                <br />
                Sales Channel :
                {{
                  option.salesChannel.filter(function (salesChannel) {
                    return salesChannel.ID == tr.SalesChannelID;
                  }).length > 1 ? option.salesChannel.filter(function (salesChannel) {
                    return salesChannel.ID == tr.SalesChannelID;
                  })[0].name : '-'
                }}
                <br />
                Territory :
                {{
                  option.territory.filter(function (territory) {
                    return territory.ID == tr.TerritoryID;
                  })[0].name
                }}
                <br />
                Source : {{ tr.Source }}
              </vs-td>
              <vs-td>
                Name : {{ data[indextr].CustomerName }}
                <br />
                Address : {{ data[indextr].CustomerAddress }}
                <br />
                Mobile : {{ data[indextr].CustomerMobile }}
                <br />
                Category :
                {{
                  option.customerCategory.filter(function (customerCategory) {
                    return customerCategory.ID == tr.CustomerCategoryID;
                  })[0].name
                }}
                <br />
              </vs-td>
              <!-- <vs-td>{{data[indextr].Code}}</vs-td> -->
              <vs-td>
                {{ dateFormat(data[indextr].DueDate) }}
                <br>
                <vs-chip v-if="diffDateWithNow(data[indextr].DueDate) < 0" color="danger">
                  Over Due
                </vs-chip>
                <vs-chip v-else-if="diffDateWithNow(data[indextr].DueDate) < 23" color="warning">
                  H-1
                </vs-chip>
              </vs-td>
              <vs-td>{{ data[indextr].SalesOrderLine }}</vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? 'nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <!-- <vs-row
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-row> -->

            <detail :data-id="dataId" :selected="selectedData"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import detail from "./detail.vue";
import moment from "moment";
export default {
  components: {
    detail,
  },
  props: [
    "territory",
    "salesChannel",
    "customerCategory",
    "startDate",
    "endDate",
  ],
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "due_date",
      },
      header: [
        {
          text: "Sales Order Number",
          // width: '5%'
          value: "code",
        },
        {
          text: "Name Customer",
          value: "customer_name",
        },
        {
          text: "Delivery Date",
          value: "due_date",
        },
        {
          text: "Total Items",
          sortable: false,
        },
      ],
      responseData: {},
      isActive: 1,
      checked: [],
      checkedCode: [],
      modelCheck: [],
      checkedAll: false,
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      option: {
        territory: [],
        salesChannel: [],
        customerCategory: [],
      },
      dataId: 0,
    };
  },
  watch: {
    territory: function () {
      // this.getData()
      this.reloadData(this.params);
    },
    salesChannel: function () {
      this.reloadData(this.params);
    },
    customerCategory: function () {
      this.reloadData(this.params);
    },
    startDate: function () {
      this.reloadData(this.params);
    },
    endDate: function () {
      this.reloadData(this.params);
    },
  },
  mounted() {
    this.$http.get("/api/v1/allocation-engine/list/territory").then((resp) => {
      if (resp.code == 200) {
        this.option.territory = resp.data.territory;
      } else {
        // this.$vs.loading.close();
        this.$router.push("/");
      }
    });
    this.$http.get("/api/v1/sales-channel/list").then((resp) => {
      console.log(resp);
      if (resp.code == 200) {
        this.option.salesChannel = resp.data;
        console.log(this.option.salesChannel);
        console.log("this.option.salesChannel");
      } else {
        this.$router.push("/");
      }
    });
    this.$http.get("/api/v1/master/customer-category-all").then((resp) => {
      if (resp.code == 200) {
        this.option.customerCategory = resp.data;
      } else {
        this.$router.push("/");
      }
    });
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    backgroundRow(tr){
      if(this.dataId == tr.id){
        return "colored"
      }else {
        const persen = parseFloat(tr.balance)/parseFloat(tr.amount)
        if(persen < 0){
          return "red"
        }else if (persen < 0.5){
          return "yellow"
        }
        return ""
      }
    },
    dateFormat(value) {
      return moment(String(value)).format("DD MMMM YYYY");
    },
    diffDateWithNow(date){
      const  x = new moment()
      var y = new moment(String(date))
      // return y
      console.log(x, y)
      var duration = moment.duration(y.diff(x))
      return duration.hours()
    },
    AllocationEngine() {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("SalesOrderID", this.checked);
      // bodyFormData.set('TerritoryID', this.territory);
      this.$http
        .post("api/v1/allocation-engine/calculate", bodyFormData)
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
            this.$vs.loading.close();
          }
          this.checked.splice(0, this.checked.length);
          this.checkedCode.splice(0, this.checkedCode.length);
          setTimeout(function () {
            this.$vs.loading.close();
          }, 3000);
        });
    },
    Cancel() {
      this.$vs.loading();
      // var bodyFormData = new FormData();
      
      // bodyFormData.set("sales_order_code", this.checkedCode);
      // bodyFormData.set("status", 1);
      const params = {
        sales_order_code : this.checkedCode,
        status: 1
      }
      // bodyFormData.set('TerritoryID', this.territory);
      this.$http
        .post("api/v1/allocation-engine/cancel-sales-order", params)
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
            this.$vs.loading.close();
          }
          this.checked.splice(0, this.checked.length);
          this.checkedCode.splice(0, this.checkedCode.length);
          setTimeout(function () {
            this.$vs.loading.close();
          }, 3000);
        });
    },
    clickTr(val) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      this.dataId = val.ID;
      this.selectedData = val;
      this.detail = true;
    },
    closeDetail() {
      this.dataId = 0;
      this.detail = false;
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val.ID)) {
        this.checked.splice(this.checked.indexOf(val.ID), 1);
        this.checkedCode.splice(this.checkedCode.indexOf(val.Code), 1);
      } else {
        this.checked.push(val.ID);
        this.checkedCode.push(val.Code);
      }
    },
    addAllChecked() {
      // console.log(this.checkedAll)
      if (this.checkedAll == false) {
        for (var i = 0; i < this.data.length; i++) {
          if (this.checked.includes(this.data[i].ID)) {
            //
          } else {
            this.checked.push(this.data[i].ID);
            this.checkedCode.push(this.data[i].Code);
            console.log(this.data[i].ID);
            console.log("this.data[i].id");
          }
        }
      } else {
        for (var j = 0; j < this.data.length; j++) {
          this.checked.splice(this.checked.indexOf(this.data[j].ID), 1);
          this.checkedCode.splice(this.checkedCode.indexOf(this.data[j].Code), 1);
        }
      }
      // this.checkedAll = null;
      console.log(this.checkedAll);
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/sales-order", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_id: this.territory.id,
            sales_channel_id: this.salesChannel.id,
            customer_category_id: this.customerCategory.id,
            start_date: this.startDate,
            end_date: this.endDate,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.salesOrder;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 30px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>