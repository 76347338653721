<template>
  <div>
     <div class="vx-row mb-12">
        <vs-button v-on:click="submitCancel()" icon="done" color="primary">Submit</vs-button>
    </div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        <!-- <vs-col style="padding-bottom: 10px"  vs-offset="0" vs-type="flex" vs-justify="left" vs-align="center" vs-w="12"> -->
        <!-- <vs-button 
            v-on:click="AllocationEngine()"
            color="success"
            icon-pack="feather"
            icon="icon-inbox"
          >Proses Delivery Plan</vs-button> -->
        <!-- </vs-col> -->
        
        <!-- TABLE ACTION ROW -->
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
         <template slot="thead">
            <th width="15%">
              <vs-checkbox v-on:click="addAllChecked()" v-model="checkedAll">Check All</vs-checkbox>
            </th>
          </template>
        <template slot="tbody">
          <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.salesOrders">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-td>
                <vs-checkbox
                  :checked="checked.includes(tr.ID)"
                  v-on:click="addChecked(tr.ID)"
                >Check</vs-checkbox>
              </vs-td>
              <vs-td>
                <vs-button
                  size="[detail ? 'small' : '', '']"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                >{{tr.Code}}</vs-button>
              </vs-td>
              <vs-td>{{tr.CustomerName}}</vs-td>
              <!-- <vs-td>{{data[indextr].CustomerAddress}}</vs-td> -->
              <vs-td>{{tr.CustomerAddress}}</vs-td>
              <!-- <vs-td></vs-td> -->
            </vs-tr>
        </template>
        </data-table>
        
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
             <vs-col style="padding-bottom: 8px"  vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth" vs-w="4">
             <vs-button 
                  size="small"
                  v-on:click="closeDetail"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                >Close</vs-button>
             </vs-col>
             <vs-col class="vertical-divider" vs-offset="0" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
             <vs-button 
                  size="small"
                  v-on:click="closeDetail"
                  color="grey"
                  icon-pack="feather"
                  icon="icon-x-square"
                ></vs-button>
            </vs-col>
            
          <detail :data-id="dataId" :delivery-plan="deliveryPlan" :edit="edit" :sales-order="salesOrder" @refresh="reloadData"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import detail from './detail.vue'
export default {
  props: ["deliveryPlan","indexItem"],
  components:{
    detail
  },
  data() {
    return {
      edit:false,
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code"
      },
      header: [
        {
          text: "Sales Order Number",
          // width: '5%'
          value: "code"
        },
        {
          text: "Name Customer",
          value: "customer_name"
        },
        {
          text: "Address",
          value: "customer_address"
        }
      ],
      responseData: {},
      isActive: 1,
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      dataId:'',
      salesOrder:0,
      checked: [],
      modelCheck: [],
      checkedAll: false,
    };
  },
  mounted() {
    this.reloadData(this.params)
  },
  methods: {
    submitCancel() {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("SalesOrderID", this.checked);
      // bodyFormData.set('TerritoryID', this.territory);
      this.$http
        .post("api/v1/allocation-engine/list/delivery-plan/draft/sales-order/disallow/post", {sales_order_ids:this.checked})
        .then(resp => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
          } else {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check"
            });
            this.reloadData(this.params)
            this.$vs.loading.close();
          }
          setTimeout(function() {
            this.$vs.loading.close();
          }, 3000);
          this.$emit("changeTabs",this.indexItem,0)

        });
    },
    clickTr(val) {
        this.closeDetail();
        setTimeout(function(){ }, 3000);
        this.dataId = val.ID;
        this.salesOrder = val.Code
        this.detail = true;
    },
    closeDetail(){
        this.detail = false;
    },
    addChecked(val) {
      console.log(this.checked.length);
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
      } else {
        this.checked.push(val);
      }
    },
    addAllChecked() {
      // console.log(this.checkedAll)
      if (this.checkedAll == false) {
        for (var i = 0; i < this.responseData.salesOrders.length; i++) {
          if (!this.checked.includes(this.responseData.salesOrders[i].ID)) {
            this.checked.push(this.responseData.salesOrders[i].ID);
            // console.log(this.data[i].ID);
            // console.log("this.data[i].id");
          }
        }
      } else {
        for (var j = 0; j < this.data.length; j++) {
          this.checked = [] //.splice(this.checked.indexOf(this.data[j].ID), 1);
        }
      }
    },
    reloadData(params) {
      this.params = params;
      this.$vs.loading();
      this.$http.get("api/v1/allocation-engine/list/delivery-plan/draft/sales-order/disallow", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            delivery_plan_id: this.deliveryPlan,
            // territory_id: this.territory
          }
        })
        .then(
          resp => {
            console.log("aye ini dis")
            if (resp.code == 500) {
              this.$vs.loading.close();
            } else if(resp.code == 200) {
              this.$vs.loading.close();
              this.data = resp.data.salesOrders;
              this.responseData = resp.data;
              if(this.data.length < 1){
                this.$emit("hideDisallow",this.indexItem)
              }
              this.checkedAll = false;
            }
            else {
              this.$vs.loading.close();
            }
          }
        );
    }
  }
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
  
}
.vertical-divider {
    border-left: 1px solid #7367f0;
    min-height:800px;
    padding:5px;
}
</style>