<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Delivery Plan">
      <div class="vx-row mb-6" style="width:50%;">
        <div class="vx-col sm:w-1/3 w-full flex items-center" style="margin-bottom: 30px;">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="territory"
            :options="optionTerritory"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            track-by="id"
            label="name"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          
        </div>
        <div class="vx-col sm:w-1/3 w-full flex items-center" style="margin-bottom: 30px;">
          <span>Sales Channel</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <!-- <datepicker :inline="false" v-model="startDate" placeholder="Select Date"></datepicker> -->
           <!-- <v-select :clearable="false" v-model="setSalesChannel" @input="setSelectedSalesChannel" :options="optionSalesChannel" >
              <template #option="{ code, name }">
                <h3 style="margin: 0">{{ name }}</h3>
                <em>{{ name }}</em>
              </template>
            </v-select> -->
           <multiselect
            class="selectExample"
            v-model="salesChannel"
            :options="optionSalesChannel"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            track-by="id"
            label="name"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="vx-col sm:w-1/3 w-full flex items-center" style="margin-bottom: 30px;">
          <span>Distribution Channel</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <!-- <datepicker :inline="false" v-model="startDate" placeholder="Select Date"></datepicker> -->
           <!-- <v-select :clearable="false" v-model="setSalesChannel" @input="setSelectedSalesChannel" :options="optionSalesChannel" >
              <template #option="{ code, name }">
                <h3 style="margin: 0">{{ name }}</h3>
                <em>{{ name }}</em>
              </template>
            </v-select> -->
           <multiselect
            class="selectExample"
            v-model="customerCategory"
            :options="optionCustomerCategory"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            track-by="id"
            label="name"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="vx-col sm:w-1/3 w-full flex items-center" style="margin-bottom: 30px;">
          <span>Start Date</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <!-- <datepicker :inline="false" v-model="startDate" placeholder="Select Date"></datepicker> -->
          <flat-pickr :config="configFlatPickr" v-model="startDate"></flat-pickr>
        </div>
        <div class="vx-col sm:w-1/3 w-full flex items-center" style="margin-bottom: 30px;">
          <span>End Date</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <!-- <datepicker :inline="false" v-model="endDate" placeholder="Select Date"></datepicker> -->
          <flat-pickr :config="configFlatPickr" v-model="endDate"></flat-pickr>
        </div>
      </div>
        <vs-tabs :color="colorx" v-model="tabs">
        <vs-tab @click="colorx = 'danger'" label="Open">
          <div class="con-tab-ejemplo">
            <open :territory="territory" :customer-category="customerCategory" :sales-channel="salesChannel" :start-date="startDate" :end-date="endDate"></open>
          </div>
        </vs-tab>
        <!-- <vs-tab @click="colorx = 'warning'" label="Proses">
                        <div class="con-tab-ejemplo">
                            <proses></proses>
                        </div>
        </vs-tab>-->
        <vs-tab @click:colorx="colorx = 'warning'" label="Draft">
          <div class="con-tab-ejemplo">
            <draft :territory="territory"></draft>
          </div>          
        </vs-tab>
        <vs-tab @click="colorx = 'warning'" label="Release">
          <div class="con-tab-ejemplo">
            <release :territory="territory"></release>
          </div>
        </vs-tab>
        <vs-tab @click="colorx = 'success'" label="Complete">
          <div class="con-tab-ejemplo">
            <complete :territory="territory"></complete>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>
<script>
import open from "./open/open.vue";
import draft from "./draftIndex.vue";
// import item from "./item/item.vue";
// import draft from "./draft/draft.vue";
// import disallow from "./disallow/disallow.vue";
import release from "./release/release.vue";
import complete from "./complete/complete.vue";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import vSelect from "vue-select";

export default {
  components: {
    open,
    // item,
    // draft,
    draft,
    // disallow,
    release,
    complete,
    flatPickr,
    vSelect
  },
  async mounted() {
    await this.showTerritory();
    // await this.getDP()
  },
  data: () => ({
    customerCategory: {},
    optionCustomerCategory:[{ID:'',Name:''}],
    territory: {}, 
    optionTerritory: [{ID:'',Name:''}],
    salesChannel: {}, //{ text: "Malang Raya", value: 1 },
    optionSalesChannel:[{ID:'',Name:''}],
    configFlatPickr: {
      // wrap: true, // set wrap to true only when using 'input-group'
       altFormat: 'F j, Y',
      // altInput: true,
      dateFormat: 'j F Y',
      enableTime: true,
      minTime: "00:00",
      plugins: [new ConfirmDatePlugin()]
    }, 
    startDate: "",
    endDate: "",
    colorx: "danger",
    items: [],
    // deliveryPlans: [],
    // numberTabs:[],
    tabs:0
  }),
  watch: {
    territory: function() {
      // this.getDP();
      // this.reloadData(this.params)
    },
    salesChannel: function(){
      // this.getDP();
    },
    customerCategory: function(){
      // this.getDP();
    }
  },
  methods: {
    showTerritory() {
      this.$http.get("/api/v1/allocation-engine/list/territory").then(
        resp => {
          if(resp.code == 200){
            this.optionTerritory = []
            this.optionTerritory.push({name:'All'})
            for(var territory = 0;territory<resp.data.territory.length;territory++){
              this.optionTerritory.push(resp.data.territory[territory])
            }
            // console.log(this.optionTerritory)
            this.territory = this.optionTerritory[0];
          }else{
            // this.$vs.loading.close();
            this.$router.push("/");
          }
        }
      );
      this.$http.get("/api/v1/sales-channel/list").then(
        resp => {
          console.log(resp)
          if(resp.code == 200){
            this.optionSalesChannel = []
            this.optionSalesChannel.push({name:'All'})
            for(var salesChannel = 0;salesChannel<resp.data.length;salesChannel++){
              this.optionSalesChannel.push(resp.data[salesChannel])
            }
            this.salesChannel = this.optionSalesChannel[0];
            // this.territory = resp.data.territory[0];
          }else{
            // this.$vs.loading.close();
            this.$router.push("/");
          }
        }
      );
      this.$http.get("/api/v1/master/customer-category-all").then(
        resp => {
          if(resp.code == 200){
            this.optionCustomerCategory = []
            this.optionCustomerCategory.push({name:'All'})
            for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
              this.optionCustomerCategory.push(resp.data[customerCategory])
            }
            this.customerCategory = this.optionCustomerCategory[0];
          }else{
            // this.$vs.loading.close();
            this.$router.push("/");
          }
        }
      );
    },
    // check(index = 0) {
    //   this.numberTabs[index] = 1
    //   this.$http
    //     .get("ae/process", {
    //       params: {
    //         delivery_plan_allocation_id: this.deliveryPlans[index].id
    //       }
    //     })
    //     .then(resp => {
    //       console.log(resp);
    //       if (resp.code != 200) {
    //         console.log(resp);
    //       } else {
    //         this.deliveryPlans[index].tab = true
    //         console.log(this.deliveryPlans[index].tab);
    //         this.$vs.loading.close();
    //       }
    //     });
    // },
    changeTabs(index,indexTab){
      this.numberTabs[index] = indexTab
    },
    hideItem(index){
      console.log(this.deliveryPlans)
      console.log("this.deliveryPlans Hide")
      this.deliveryPlans[index].tab = false
      this.deliveryPlans[index].disallow = false
      this.numberTabs[index] = 0
    },
    hideDisallow(index){
      console.log(this.deliveryPlans)
      this.deliveryPlans[index].disallow = false
      this.numberTabs[index] = 0
    },
    approveDelivery(deliveryPlanId) {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("DeliveryPlanID", deliveryPlanId);
      bodyFormData.set("status", 1);
      this.$http
        .put(
          "api/v1/allocation-engine/list/delivery-plan/store/status",
          bodyFormData
        )
        .then(resp => {
          if(resp.code)
          this.$vs.loading.close();
          // if (resp.data.code == 500) {
          // } else {
          this.getDP();
          // }
        });
    },
    getDP() {
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft", {
          params: {
            status: 0,
            territory: this.territory.id
          }
        })
        .then(
          resp => {
            if (resp.code == 200) {
              this.deliveryPlans = resp.data.deliveryPlan;
              console.log(resp.data.deliveryPlan)
              console.log("resp.data.deliveryPlan")
              if (resp.data.deliveryPlan.length != 0) {
                for (var i = 0; i <= resp.data.deliveryPlan.length; i++) {
                  this.deliveryPlans[i].tab = true;
                  this.deliveryPlans[i].disallow = true;
                  this.numberTabs[i] = 1
                  console.log("masuk sini")
                }
              }
            }
            this.$vs.loading.close();
          },
        );
      // this.tabs = 0
    }
  }
};
</script>
<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
.divider-bottom {
  border-bottom: 1px solid #6c6cec;
  /* min-height:800px; */
  padding: 5px;
}
</style>