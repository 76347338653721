<template>
    <div class="con-tab-ejemplo">
        <div class="vx-row match-height">
            <!-- SIMPLE CARD WITH TABS -->
            <div class="vx-row w-full flex mb-12">
            <vx-card
                :key="index"
                class="divider-bottom"
                v-for="(deliveryPlan, index) in deliveryPlans"
            >
                <div class="flex mb-12">
                <div class="md:w-1/3">
                <vs-button
                    v-on:click="approveDelivery(deliveryPlan.DeliveryPlanID)"
                    color="success"
                    icon-pack="feather"
                    icon="icon-inbox"
                    >Send Delivery Plan</vs-button>
                    <br>
                    <b>Delivery Plan Number</b>
                    : {{deliveryPlan.DeliveryPlanCode}}
                    <br />
                    <b>Warehouse</b>
                    : {{deliveryPlan.WarehouseName}}
                    <br />
                    <b>Company</b>
                    {{deliveryPlan.CompanyName}}
                    <br>
                    
                </div>
                <div class="md:w-1/3"></div>
                <div class="md:w-1/3">
                    
                </div>
                </div>
                <vs-tabs v-model="numberTabs[index]">
                    <vs-tab @click="colorx = 'warning'" label="Sales Order">
                        <!-- {{deliveryPlan.ID}} -->
                        
                        <draft :delivery-plan="deliveryPlan.DeliveryPlanID"></draft>
                    </vs-tab>
                        <vs-tab v-show="itemShow[index]" @click="colorx = 'danger'" label="items">
                            <item  :delivery-plan="deliveryPlan.DeliveryPlanID" :index-item="index"></item>
                        </vs-tab>
                    <vs-tab  v-show="disallowShow[index]" @click="colorx = 'warning'" label="Non-Partial Sales Order">
                        <!-- {{deliveryPlan.ID}} -->
                        <disallow  @changeTabs="changeTabs" :delivery-plan="deliveryPlan.DeliveryPlanID"></disallow>
                    </vs-tab>
                
                </vs-tabs>
            </vx-card>
            <vx-card  v-if="deliveryPlans.length==0">Empty :)</vx-card>
            </div>
        </div>
    </div>
</template>
<script>

import item from "./item/item.vue";
import draft from "./draft/draft.vue";
import disallow from "./disallow/disallow.vue";

export default {
    components: {
        item,
        draft,
        disallow,
        
    },
    props:{
        territory :  Object,
        // option : Object
    },
    watch:{
      territory: function() {
      // this.getData()
      this.getDP();
    }
    },
    mounted(){
        this.$vs.loading();
         this.getDP()
        this.$vs.loading.close();
    },
    computed:{
      
         isItemsDisable : {
            get:function(){
            //  console.log(this.itemShow[index])
             return this.itemShow
            }
         },
         isDisallowDisable : {
            get: function(){
                console.log(this.disallowShow)
                console.log("this.disallowShow")
             return this.disallowShow
            }
         }
    },
  data: () => ({
    deliveryPlans: [],
    numberTabs:[],
    itemShow:[],
    disallowShow:[],
    params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "item_code"
    },
  }),
  methods:{
      getDP() {
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft", {
          params: {
            status: 0,
            territory: this.territory.id
          }
        })
        .then(
          resp => {
            if (resp.code == 200) {
              this.deliveryPlans = resp.data.deliveryPlan;
              if (resp.data.deliveryPlan.length != 0) {
                for (var i in this.deliveryPlans) {
                    this.itemShow.push(true)
                    this.disallowShow.push(true)
                    console.log(this.deliveryPlans[i])
                    console.log("this.deliveryPlans[i]")

                    this.checkItem(this.params,this.deliveryPlans[i].DeliveryPlanID,i)
                }
              }
            }
            this.$vs.loading.close();
          },
        );
        console.log(this.disallowShow)
        console.log(this.itemShow)
      // this.tabs = 0
    },
    showtab: function (indexDP,indexTab) {
        if(indexTab == 1){
            console.log(this.deliveryPlans)
            console.log(this.deliveryPlans[indexDP].tab)
            console.log(indexDP)
            console.log("this.deliveryPlans[indexDP].tab")
            return this.deliveryPlans[indexDP].tab
        }
        else{
            return this.deliveryPlans[indexDP].disallow; 
        }
    },
    changeTabs(index,indexTab){
      this.numberTabs[index] = indexTab
    },
    hideItem(index){
      console.log(this.deliveryPlans)
      console.log("this.deliveryPlans Hide")
      this.deliveryPlans[index].tab = false
      this.deliveryPlans[index].disallow = false
      this.numberTabs[index] = 0
    },
    hideDisallow(index){
      console.log(this.deliveryPlans)
      this.deliveryPlans[index].disallow = false
      this.numberTabs[index] = 0
    },
    approveDelivery(deliveryPlanId) {
      this.$vs.loading();
      var bodyFormData = new FormData();
      bodyFormData.set("DeliveryPlanID", deliveryPlanId);
      bodyFormData.set("status", 2);
      this.$http
        .put(
          "api/v1/allocation-engine/list/delivery-plan/store/status",
          bodyFormData
        )
        .then(resp => {
          console.log(resp.code)
          this.$vs.loading.close();
          this.deliveryPlans = []
          // if (resp.data.code == 500) {
          // } else {
          this.getDP();
          // }
        });
    },
    checkItem:function(params,deliveryPlan,index){
        this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft/item", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            delivery_plan_id: deliveryPlan,
            // territory_id: this.territory
          }
        })
        .then(
          resp => {
            
            if(resp.code == 200) {
              if(resp.data.items.length < 1){
                  
                this.itemShow[index] = false
                this.disallowShow[index] = false
                // this.$emit("hideItem",this.indexItem)
                // console.log("aye ini ke hide")
              }else{
                this.itemShow[index] = true
                this.checkDisallow(params,deliveryPlan,index)
              }
            }
            else {
              this.$vs.loading.close();
            }
          }
        );
    },
    checkDisallow: function(params,deliveryPlan,index){
        this.$http.get("api/v1/allocation-engine/list/delivery-plan/draft/sales-order/disallow", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            delivery_plan_id: deliveryPlan,
            // territory_id: this.territory
          }
        })
        .then(
          resp => {
            if(resp.code == 200) {
                if(resp.data.salesOrders.length < 1){
                    this.disallowShow[index] = false
                }else{
                    this.itemShow[index] = true
                    this.disallowShow[index] = true
                    // this.deliveryPlans[index].tab = true
                }
            }
          }
        );
    }
  },
}
</script>