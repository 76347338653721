<template>
  <div >
    <div class="vx-row margin-btm" style="width:100%">
      <div class="vx-col sm:w-3/3 w-full">
        <span>{{selected.Code}}</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">{{selected.CustomerName}}</div>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
     <template slot="tbody">
       <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.salesOrderLine">
          <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
          <vs-td style="align-item-center">{{tr.ItemName}}</vs-td>
          <vs-td>{{tr.Quantity}} ({{tr.ItemUnit}})</vs-td>
        </vs-tr>
     </template>
     </data-table>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
    dataId: Number,
    selected: {}
  },
  // created() {
  // },
  // mounted() {
  // },
  watch: {
    dataId() {
      this.reloadData(this.params);
      // console.log('The dataId has changed!')
    }
  },
  data: () => ({
    params: {
      search: "",
      length: 10,
      page: 1,
      order: "asc",
      sort: "item_name"
    },
    header: [
      {
        text: "SKU Name",
        value:'item_name'
        // width: '5%'
      },
      {
        text: "Quantity Request",
        // value: "name"
      },
    ],
    responseData: {}
  }),
  methods: {
    reloadData(params) {
      console.log("sip")
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/sales-order/lines", {
          params: {
            sales_order_id: this.dataId,
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort
          }
        })
        .then(
          resp => {
            if(resp.code == 200){
              this.responseData = resp.data;
            }
            this.$vs.loading.close();
          }
        );
    }
  }
};
</script>