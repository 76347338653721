<template>
  <div class="vx-row mb-12">
    <div class="vx-row mb-12" style="width:100%">
					<div class="vx-col sm:w-1/3 w-full">
						<span>Delivery Plan</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<vs-input class="w-full" v-model="salesOrder" disabled />
					</div>
				</div>
    
    <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.salesOrder">
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
            <vs-td style="align-item-center">{{tr.Code}}</vs-td>
          <vs-td>{{tr.CustomerName}}</vs-td>
          <vs-td>{{tr.CustomerAddress}}</vs-td>
            </vs-tr>
          </template>
        </data-table>
   
  </div>
</template>
<script>
export default {
  
  props: ["dataId","salesOrder"],
  
  created(){
    console.log("created")
  },
  mounted(){
      this.reloadData(this.params)
  },
   watch: {
    dataId() {
      this.reloadData(this.params)
      // console.log('The dataId has changed!')
    }
  },
  computed: {
    
    currentPageDetail: {
      get() {
        return 1;
      },
      set(val) {
        this.pageDetail(val);
        
      }
    }
  },
  data: () => ({
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code"
      },
      header: [
        {
          text: "Sales Order Number",
          // width: '5%'
          value: "code"
        },
        {
          text: "Customer Name",
          // value: "customer_name"
        },
        {
          text: "Customer Address",
          sortable: false
        }
      ],
      responseData: {},
      limits: [5,10, 25, 50],
      limitShow: 10,
      drawData: 0,
      isActive: 1,
      checked: [],
      modelCheck: [],
      checkedAll: false,
      search: "",
      detailShow: "core vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      recordsTotal: 0,
      maxPage: 2,
      dataEnd: 0,
      data: [{ id: 1 }]
  }),
  methods: {
    reloadData(params) {
      this.params = params;
      console.log(params)
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft/sales-order", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            delivery_plan_id: this.dataId,
          }
        })
        .then(
          resp => {
            if (resp.code == 500) {
              this.$vs.loading.close();
            } else if(resp.code == 200) {
              this.$vs.loading.close();
              this.data = resp.data.deliveryPlan;
              this.responseData = resp.data;
              this.checkedAll = false;
            }
            else {
              this.$vs.loading.close();
            }
          }
        );
    },
    pageDetail(val) {
      this.getData(val);
      return val;
    },
    changeLimit(val) {
      this.limitShow = val;
      this.getData(this.isActive);
    },
    getData(val = 1) {
      this.isActive = val;
      this.$vs.loading();
      this.$http
        .get("api/v1/allocation-engine/list/delivery-plan/draft/sales-order", {
          params: {
            delivery_plan_id: this.dataId,
            length: this.limitShow,
            search: this.search,
            draw: this.drawData,
            start: this.limitShow * (val - 1)
          }
        })
        .then(resp => {
            this.drawData = this.drawData + 1;
            this.data = resp.data.salesOrder;
            this.recordsTotal = resp.data.recordsTotal;
            this.maxPage = Math.ceil(this.data.recordsTotal / this.limitShow);
            this.dataEnd = resp.data.salesOrder.length;
            this.checkedAll = false;
            this.$vs.loading.close();
          
        });
    }
  }
  }

</script>